import React from "react";
import BG from "../images/loginBg.jpg";

const Career = () => {
  return (
    <div className="w-full bg-white  py-16 px-4 '">
      <div className=" flex justify-center">
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
            <div>
              <h5 className="mr-3 font-semibold dark:text-white">
                App Developer
              </h5>
              <p className="text-gray-500 dark:text-gray-400"> React Native</p>
              <p className="text-gray-500 dark:text-gray-400">
                (Intermediate - Advanced)
              </p>
              <p className="text-gray-500 dark:text-gray-400">
                Number of openings: 2{" "}
              </p>
              <p className="text-gray-500 dark:text-gray-400">
                Duration: 6 months
              </p>
              <p className="text-gray-500 dark:text-gray-400">
                Working Mode: Hybrid
              </p>
              <p className="text-gray-500 dark:text-gray-400">
                Office Location: Delhi
              </p>

              <p className="text-gray-500 dark:text-gray-400">Stipend: Yes </p>
            </div>
            <button className="rounded-md border-2 border-primary bg-primary px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-primary/80 bg-purple-600">
              <a link rel="" type="" href="https://forms.gle/5j4v7Tz2B2djWusEA">
                Apply Now
              </a>
            </button>
          </div>
        </div>
        <p className=" flex  p-6"></p>
        <div className="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
          <div className="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
            <div>
              <h5 className="mr-3 font-semibold dark:text-white">
                UI/UX Designer
              </h5>
              <p className="text-gray-500 dark:text-gray-400">
                (Intermediate - Advanced)
              </p>
              <p className="text-gray-500 dark:text-gray-400">
                Number of openings: 2{" "}
              </p>
              <p className="text-gray-500 dark:text-gray-400">
                Duration: 6 months
              </p>
              <p className="text-gray-500 dark:text-gray-400">
                Working Mode: Hybrid
              </p>
              <p className="text-gray-500 dark:text-gray-400">
                Office Location: Delhi
              </p>
              <p className="text-gray-500 dark:text-gray-400">Stipend: Yes </p>
            </div>
            <button className="rounded-md border-2 border-primary bg-primary px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-primary/80 bg-purple-600">
              <a link rel="" type="" href="https://forms.gle/VZ6oVueZcZbBMDNT6">
                Apply Now
              </a>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Career;
