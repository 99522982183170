import React from 'react'
import UserMenu from '../Utils/UserMenu';
import { FaRegBuilding } from "react-icons/fa6";
import { IoWallet, IoRefreshOutline } from "react-icons/io5";
import { Button, IconButton } from '@chakra-ui/react';
import { useAuth } from '../Utils/AuthContext';

const UserHeader = ({ sidebarOpen, setSidebarOpen }) => {

  const {userData} = useAuth();
  
    return (
      <header className="sticky top-2 mx-2 rounded-2xl bg-slate-800 border-b border-slate-200 z-30">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-14 -mb-px">
            {/* Header: Left side */}
            <div className="flex">
              {/* Hamburger button */}
              <button
                className="text-white mr-3 hover:text-slate-600 lg:hidden"
                aria-controls="sidebar"
                aria-expanded={sidebarOpen}
                onClick={(e) => {
                  e.stopPropagation();
                  setSidebarOpen(!sidebarOpen);
                }}
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6 fill-current"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect x="4" y="5" width="16" height="2" />
                  <rect x="4" y="11" width="16" height="2" />
                  <rect x="4" y="17" width="16" height="2" />
                </svg>
              </button>
              <div className='text-white flex gap-2 items-center'>
               <FaRegBuilding size={'18px'} />
               <h6 className='font-semibold'>Shrufit</h6>
              </div>
            </div>
  
            {/* Header: Right side */}
            <div className="flex items-center gap-3">
              <div className='text-white flex gap-2 items-center border-r px-2'>
               <IoWallet size={'18px'} />
               <h6 className='font-medium'>₹ 100.00</h6>
               {/* <div className='hover:bg-gray-200 p-1 rounded-md cursor-pointer'>
                <IoRefreshOutline size={'18px'} className='text-white hover:text-purple-600'/>
               </div> */}
              </div>
              <Button variant={'solid'} size={'xs'} colorScheme='purple' >Recharge Wallet</Button>
               <UserMenu />
            </div>
          </div>
        </div>
      </header>
    );
  }

export default UserHeader