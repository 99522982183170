import React from "react";

const Settings = () => {
  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Settings</h1>
      </div>
    </div>
  );
};

export default Settings;
