import { createBrowserRouter } from 'react-router-dom'
import Home from './Components/Home'
import AppLayout from './AppLayout'
import UserOutlet from './UserComponents/UserOutlet'
import UserDashboard from './UserComponents/UserDashboard'
import UserProfile from './UserComponents/UserProfile'
import MyShipments from './UserComponents/MyShipments'
import DeliverOrder from './Components/DeliverOrder'
import Login from './Components/Login'
import BecomeACourier from './Components/BecomeACourier'
import About from './Components/About'
import Career from './Components/Career'
import PrivateRoute from './Utils/ProtectedRoute';
import ContactUs from './Components/ContactUs'
import SuperLogin from './SuperAdmin/SuperLogin'
import SuperLayout from './SuperAdmin/SuperLayout'
import SuperDashboard from './SuperAdmin/SuperDashboard'
import AddOrder from './UserComponents/AddOrder'
import Settings from './UserComponents/Settings'

export const AppRouter = createBrowserRouter([
    {
        path: '/',
        element: <AppLayout/>,
        children : [
            {
                path: '/',
                element: <Home/>
            },
            {
                path: '/deliver',
                element: <DeliverOrder/>
            },
            {
                path: '/login',
                element: <Login/>
            },
            {
                path: '/courier',
                element: <BecomeACourier/>
            },
            {
                path: '/about',
                element: <About/>
            },
            {
                path: '/career',
                element: <Career/>
            },
            {
                path: '/contact-us',
                element: <ContactUs/>
            },
        ]
    },
    {
        path: '/lg-admin',
        element: <SuperLogin/>
    },
    {
        path: '/user',
        // element: <PrivateRoute component={UserOutlet}/>,
        element: <UserOutlet />,
        children : [
            {
                path: 'dashboard',
                element: <UserDashboard/>
            },
            {
                path: 'profile',
                element: <UserProfile/>
            },
            {
                path: 'addorder',
                element: <AddOrder/>
            },
            {
                path: 'shipments',
                element: <MyShipments/>
            },
            {
                path: 'settings',
                element: <Settings/>
            }
        ]
    },
    {
        path: '/superadmin',
        element: <SuperLayout />,
        children : [
            {
                path: 'dashboard',
                element: <SuperDashboard/>
            },
        ]
    },

])