import { Avatar, Button, Input, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'
import { FiEdit3 } from 'react-icons/fi'

const UserProfile = () => {

  const [EditStatus, setEditStatus] = useState({
    isPersonalEdit: false
  })
  return (
    <div>

      <div className='grid grid-cols-2 gap-4'>
        <div className='bg-white p-4 rounded-2xl drop-shadow-md'>
          <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
            <h1 className="text-lg font-bold text-gray-800">Personal Details</h1>
            {!EditStatus?.isPersonalEdit && <Button onClick={()=>{setEditStatus({...EditStatus,isPersonalEdit:true})}} leftIcon={<FiEdit3 size={'16px'}/>} variant={'ghost'} size={'sm'} colorScheme='purple' >Edit</Button>}
          </div>

          {!EditStatus?.isPersonalEdit ? 
            <div className='flex gap-4 items-center'>
              <Avatar size={'xl'} name='Arpit Sharma' />
              <div className='grid grid-cols-2 gap-2 w-full'>
             <div className="flex gap-4">
               <h6 className="font-medium">Full Name:</h6>
               <p className="font-medium">Arpit Sharma</p>
             </div>
             <div className="flex gap-4">
               <h6 className="font-medium">Mobile:</h6>
               <p className="font-medium">9911019990</p>
             </div>
             <div className="flex gap-4">
               <h6 className="font-medium">Email:</h6>
               <p className="font-medium">arpitsharma6666@gmail.com</p>
             </div>
             <div className="flex gap-4">
               <h6 className="font-medium">Address:</h6>
               <p className="font-medium">Delhi, India</p>
             </div>
            </div>
            </div> :
            <div className='space-y-4' >
              <div className='space-y-2'>
                <label>Full Name</label>
                <Input borderColor={'gray.400'} placeholder='Enter Full Name' /> 
              </div>
              <div className='space-y-2'>
                <label>Mobile Number</label>
                <Input borderColor={'gray.400'} placeholder='Enter Mobile' /> 
              </div>
              <div className='space-y-2'>
                <label>Email</label>
                <Input borderColor={'gray.400'} placeholder='Enter Email' /> 
              </div>
              <div className='space-y-2'>
                <label>Full Name</label>
                <Textarea borderColor={'gray.400'} placeholder='Enter Address' /> 
              </div>

              <div className='text-right space-x-4'>
                <Button colorScheme='purple'>Save</Button>
                <Button onClick={()=>{setEditStatus({...EditStatus,isPersonalEdit:false})}} variant={'outline'} colorScheme='purple'>Cancel</Button>
              </div>
            </div>
          }

        </div>

      </div>
    </div>
  )
}

export default UserProfile