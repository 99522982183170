import React from "react";
import IMG from "../images/delivery-boy.png";
import SERVICE from "../images/service.png";
// import {ReactTyped} from 'react-typed';
import REL from "../images/delivered.png";
import EFF from "../images/drone delivery.png";
import INN from "../images/MultiboxMultipointDelivery.png";
import CF from "../images/track.png";

import STORY from "../images/logistics-flowchart.png";
import VISION from "../images/Vision.png";
import MISSION from "../images/Vision1.png";

import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="w-full bg-white py-{50%} px-{100%}   ">
      <section className="text-gray-600 bg-slate-200 py-2 px-32">
        <div className="container mx-auto flex px-5 py-4 md:py-8 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1
              data-aos="fade-up"
              className="font-extrabold text-4xl lg:text-5xl mb-4  text-gray-900"
            >
              Pahucha
              <br className="hidden lg:inline-block" /> Denge !!!
            </h1>
            <p data-aos="fade-up" className="mb-4">
              Fast intractity courier delivery service. <br /> We make delivery
              for your business easier
            </p>
            <div className="space-y-4 w-full max-w-sm"></div>

            <div className=" mt-4 space-y-3"></div>
          </div>
          <div className="lg:max-w-xl lg:w-full md:w-1/2 w-80">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={SERVICE}
            />
          </div>
        </div>
      </section>

      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 px-4 py-4"
        data-aos="fade-right"
      >
        <div className="flex flex-col justify-center">
          <p className="text-purple-600 font-bold ">About LogistriX</p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Our Story
          </h1>
          <p>
            Founded in 2022, LogistriX emerged with a clear vision: to
            revolutionize intra-city logistics in Delhi. What started as a dream
            has now become a reality, as we stride forward, constantly evolving
            to meet the dynamic needs of our customers.
          </p>
        </div>
        <img className="w-[250px] mx-auto my-4" src={STORY} alt="/" />
      </div>

      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 px-4 py-4"
        data-aos="fade-left"
      >
        <img className="w-[250px] mx-auto my-4" src={MISSION} alt="/" />
        <div className="flex flex-col justify-center">
          <p className="text-purple-600 font-bold "> </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Our Mission
          </h1>
          <p>
            At LogistriX, our mission is simple yet ambitious - to provide
            hassle-free same-day delivery services that redefine convenience and
            reliability. We are dedicated to creating value not only for our
            customers but for the entire ecosystem of stakeholders, fostering a
            community where trust and opportunity flourish.
          </p>
          {/* <button className="rounded-md border-2 border-primary bg-primary px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-primary/80 bg-purple-600">
                 <a></a> Join Our team
                </button> */}
        </div>
      </div>

      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 px-4 py-4"
        data-aos="fade-right"
      >
        <div className="flex flex-col justify-center">
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Our Vision
          </h1>
          <p>
            We envision a future where logistics is not just a service, but a
            seamless experience that enriches lives and empowers businesses.
            With innovation at our core, we aim to set new standards in the
            industry and pave the way for a more connected and efficient city.
          </p>
        </div>
        <img className="w-[250px] mx-auto my-4" src={VISION} alt="/" />
      </div>

      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 px-4 py-4"
        data-aos="fade-left"
      >
        <img className="w-[250px] mx-auto my-4" src={IMG} alt="/" />
        <div className="flex flex-col justify-center">
          <p className="text-purple-600 font-bold "> </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            Our Team
          </h1>
          <p>
            Behind every successful delivery is a team of dedicated individuals
            who work tirelessly to ensure your satisfaction. Meet the faces
            behind LogistriX - a diverse group of professionals united by a
            passion for excellence and a drive to exceed expectations.
          </p>
          {/* <button className="rounded-md border-2 border-primary bg-primary px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-primary/80 bg-purple-600">
                 <a></a> Join Our team
                </button> */}
        </div>
      </div>

      {/* <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'> */}

      <p>
        <br />
      </p>

      <section className="text-gray-600 bg-slate-200 py-4 px-32">
        <div className="container mx-auto flex px-5 py-2 md:py-2 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1
              data-aos="fade-up"
              className="font-extrabold text-4xl lg:text-5xl mb-4  text-gray-900"
            >
              Join the LogistriX Community
            </h1>
            <p data-aos="fade-up" className="mb-4">
              Whether you're a customer, a partner, or looking to join our team,
              we invite you to be part of the LogistriX journey. <br />
              Together, let's build a future where logistics is synonymous with
              efficiency, reliability, and trust.
            </p>
            <div className="space-y-4 w-full max-w-sm"></div>
            <div className=" mt-4 space-y-3"></div>

            <div className="flex justify-center">
              <button className="rounded-md border-2 border-primary bg-primary px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-purple-500 bg-purple-600 ">
                <Link Link to="/Login">
                  {" "}
                  Join Our team{" "}
                </Link>
              </button>
            </div>
          </div>
          <div className="lg:max-w-xl lg:w-full md:w-1/2 w-80">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src="/images/landingpic.png"
            />
          </div>
        </div>
      </section>

      {/* <div className='flex flex-col justify-center'>
          <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-center'>Join the LogistriX Community</h1>
          <p className=' sm:text-l text-l py-2 text-center'>
          Whether you're a customer, a partner, or looking to join our team, we invite you to be part of the LogistriX journey. Together, let's build a future where logistics is synonymous with efficiency, reliability, and trust.
          </p>
          <h1 className='text-purple-600 font-bold text-center'>Experience the difference with LogistriX - Where Deliveries Meet Excellence.</h1>
          
        </div>
        <div className="flex justify-center">
        <button className="rounded-md border-2 border-primary bg-primary px-4 py-2 text-sm text-white transition-colors duration-300 hover:bg-purple-500 bg-purple-600 ">
             <a></a> Join Our team
                </button>

      </div> */}

      <p>
        <br />
      </p>

      <div className="flex justify-center">
        <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
          Why Choose LogistriX?
        </h1>
      </div>
      <p>
        <br></br>
      </p>

      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <img className="w-20 mx-auto mt-[-3rem] bg-white" src={REL} alt="/" />
          <h2 className="text-2xl font-bold text-center py-8 text-purple-600">
            Reliability
          </h2>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">
              {" "}
              With LogistriX, you can trust that your deliveries will arrive on
              time, every time.
            </p>
          </div>
        </div>
        {/* <div className='w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300'> */}
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <img
            className="w-20 mx-auto mt-[-3rem] bg-transparent"
            src={EFF}
            alt="/"
          />
          <h2 className="text-2xl font-bold text-center py-8 text-purple-600 ">
            Efficiency
          </h2>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">
              We leverage the latest technology and best practices to streamline
              our operations, saving you time and money.
            </p>
          </div>
        </div>
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <img className="w-20 mx-auto mt-[-3rem] bg-white" src={CF} alt="/" />
          <h2 className="text-2xl font-bold text-center py-8 text-purple-600 ">
            Customer Focus
          </h2>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">
              Your needs are our top priority. From order to delivery, we are
              committed to providing personalized solutions tailored to your
              requirements.
            </p>
          </div>
        </div>

        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <img className="w-20 mx-auto mt-[-3rem] bg-white" src={INN} alt="/" />
          <h2 className="text-2xl font-bold text-center py-8 text-purple-600">
            Innovation
          </h2>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">
              We are not afraid to challenge the status quo. Through continuous
              innovation, we strive to stay ahead of the curve and anticipate
              the future needs of our customers.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
