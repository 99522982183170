import React, { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faMobileScreenButton, faMoneyBill1Wave, faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { Typewriter } from 'react-simple-typewriter'
import { Link } from 'react-router-dom';
function Home() {

  const [BenefitSection, setBenefitSection] = useState([
    {
      benefitId: 1,
      benefitImage: '/images/DeliveryProof.png',
      benTitle: 'Proof of Delivery',
      benefitDescription: 'Receive instant digital proof of delivery, ensuring your package has safely reached its destination'
    },
    {
      benefitId: 2,
      benefitImage: '/images/PaymentTrust.png',
      benTitle: 'Transparent Pricing',
      benefitDescription: 'Enjoy clear and upfront pricing with no hidden fees, giving you full cost transparency for every delivery'
    },
    {
      benefitId: 3,
      benefitImage: '/images/OrderScheduling.png',
      benTitle: 'Order Scheduling',
      benefitDescription: 'Easily schedule your deliveries at your convenience, ensuring they arrive exactly when you need them'
    },
    {
      benefitId: 4,
      benefitImage: '/images/MultiDropShipment.png',
      benTitle: 'Multi-Drop Shipments',
      benefitDescription: 'Optimize your delivery route with our efficient multi-drop shipment service, handling multiple deliveries in a single trip'
    },
    {
      benefitId: 5,
      benefitImage: '/images/Support.png',
      benTitle: '24x7 Support',
      benefitDescription: 'Access reliable and friendly customer support at any time, day or night, to assist with all your delivery needs'
    },
    {
      benefitId: 6,
      benefitImage: '/images/LiveTracking.png',
      benTitle: 'Live Tracking',
      benefitDescription: "Monitor your delivery in real-time with live tracking, providing you with up-to-the-minute updates on your package's journey"
    },
  ])


  return (
    <div>
        <section className="text-gray-600 bg-slate-200 py-8 px-12">
  <div className="max-w-screen-xl mx-auto flex px-5 py-16 md:py-24 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 data-aos="fade-up" className="font-extrabold text-4xl lg:text-5xl mb-4  text-gray-900">Delivery Partner
        <br className="hidden lg:inline-block"/> You Can Trust
      </h1>
      <p data-aos="fade-up" className='mb-4'>Fast intractity courier delivery service. <br /> We make delivery for your business easier</p>
      <div className='space-y-4 w-full max-w-sm'>

      </div>

      <div className=" mt-4 space-y-3">
      {/* <button className=" rounded-lg px-3 w-80 md:w-96 py-2 bg-purple-600  hover:bg-purple-600 hover:drop-shadow-lg text-white font-semibold" >Deliver<FontAwesomeIcon icon={faTruckFast} className='ml-2' /></button> */}
      </div>
    </div>
    <div className="lg:max-w-xl lg:w-full md:w-1/2 w-80">
      <img className="object-cover object-center rounded" alt="hero" src="/images/landingpic.png"/>
    </div>
  </div>
</section>

<section className="text-gray-600 body-font">
  <div className="max-w-screen-xl px-5 text-center py-24 mx-auto">
    <div className="text-center mb-20 ">
      <h1 className="sm:text-3xl text-3xl font-semibold  text-gray-900 mb-4">Benefits of using Sprintr</h1>
      <p className=" leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">We at Sprintr believe in service satisfaction.</p>
      <div className="flex mt-6 justify-center">
        <div className="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
      </div>
    </div>
    <div className="flex flex-wrap justify-center sm:-m-4 -mx-4 -mb-10 -mt-4 md:space-y-0 space-y-6">
      {BenefitSection?.map((data)=>{
        return <div key={data?.benefitId} data-aos="fade-up" className="p-4 md:w-1/3 flex flex-col text-center items-center">
        <img className='h-28' src={data?.benefitImage} alt={data?.benTitle} />
        <div className="flex-grow space-y-2">
          <h2 className="text-gray-800 text-lg font-semibold">{data?.benTitle}</h2>
          <p className="leading-relaxed text-sm text-gray-500">{data?.benefitDescription}</p>
        </div>
      </div>
      })}
    </div>
  </div>
</section>


<section className="text-gray-600 bg-slate-200">
  <div className="max-w-screen-xl mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <h1 className=" sm:text-4xl text-4xl mb-4 font-bold text-gray-900">A Wide Range of Servicing Offering
        <br className="hidden lg:inline-block"/>    <span className='text-purple-600'><Typewriter
            words={['E-commerce', 'SME', 'Dedicated', 'Documents','Return Orders', 'COD Handling']}
            loop={0}
            cursor
            cursorStyle='|'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          /></span>&nbsp;&nbsp;Delivery Solutions
      </h1>
      <p className="mb-8 leading-relaxed">We offer an all hyperlocal delivery solutions you are looking for, Ecommerce deliveries, Instant deliveries, personal deliveries and delivery on demand - All under one roof.</p>
      <div className="flex justify-center">
        <Link to={'/deliver'}>
         <Button rightIcon={<FontAwesomeIcon icon={faTruckFast} />} colorScheme='purple'>Deliver Now </Button>
        </Link>
      </div>
    </div>
    <div className="lg:max-w-xl lg:w-full md:w-1/2 w-5/6">
      <img className="object-cover object-center rounded" alt="Sprintr-delivery-solutions" src="/images/delivery.png"/>
    </div>
  </div>
</section>

<section className='bg-purple-500 py-16 px-5'>

  <div className='max-w-screen-xl mx-auto flex justify-between flex-wrap'>
    <h2 className='text-3xl font-medium text-white'>Are you a Seller? Use <span className='text-4xl font-semibold'>Sprintr for Business</span> <br /> for customer deliveries in your city</h2>
    <div className='my-auto '>
      <Button rightIcon={<FontAwesomeIcon icon={faArrowRight}/>} className='my-4' >Get Quote</Button>
    </div>
  </div>

</section>


{/* <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto flex flex-wrap">
        <div className="flex flex-wrap -mx-4 mt-auto mb-auto lg:w-1/2 sm:w-2/3 content-start sm:pr-10">
          <div className="w-full sm:p-4 px-4 mb-6">
            <h1 className=" font-semibold text-2xl mb-2 text-gray-900">Growing and Focusing on Quality Service</h1>
            <p className="leading-relaxed">Creating a logistics system that fulfill the same day deliveries and provide opportunity to everyone to fulfill these logistics needs.</p>
          </div>
          <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
            <h2 className=" font-medium text-3xl text-gray-900">1K</h2>
            <p className="leading-relaxed">Users</p>
          </div>
          <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
            <h2 className=" font-medium text-3xl text-gray-900">1.8K</h2>
            <p className="leading-relaxed">Deliveries</p>
          </div>
          <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
            <h2 className=" font-medium text-3xl text-gray-900">35</h2>
            <p className="leading-relaxed">Downloads</p>
          </div>
          <div className="p-4 sm:w-1/2 lg:w-1/4 w-1/2">
            <h2 className=" font-medium text-3xl text-gray-900">4</h2>
            <p className="leading-relaxed">Cities</p>
          </div>
        </div>
        <div className="lg:w-1/2 sm:w-1/3 w-full rounded-lg overflow-hidden mt-6 sm:mt-0">
          <img className="object-cover object-center w-full h-full" src="/images/stats.png" alt="stats"/>
        </div>
      </div>
    </section> */}
    </div>
  )
}

export default Home