import { Button, Checkbox, HStack, Input, InputGroup, InputRightElement, PinInput, PinInputField } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";
import {FiArrowLeft} from 'react-icons/fi'
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "react-feather";
import { useAuth } from "../Utils/AuthContext";
import usePost from "../Utils/usePost";

function Login() {

  const { login } = useAuth();

  const  { IsPostLoading: IsSignInLoading, PostData: PostSignInData } = usePost('/sprint/user/autenticateby/pin');
  const  { IsPostLoading: IsSignUpLoading, PostData: PostSignUpData } = usePost('/sprint/user/register/customer');


  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const navigate = useNavigate();

  const [VerificationStatus, setVerificationStatus] = useState({
    isOtpRequest: false,
    isOtpVerified: true
  })

  const [IsCreateAccount, setIsCreateAccount] = useState(false);
  const [SignUpDetails, setSignUpDetails] = useState({
    mobileNo: "",
    email: "",
    customerName: "",
    password: "",
    pin: "",
    businessType:"",
    termsCondition: false,
    loggedInNumber:"",
    loggedInPassword:""
  });


  const HandleSignUp = async (e) => {
    e.preventDefault();
    let body = {
        request:{
          requestData: {
            customerName: SignUpDetails?.customerName,
            mobileNo: SignUpDetails?.mobileNo,
            email: SignUpDetails?.email,
            password: SignUpDetails?.password,
            pin: SignUpDetails?.pin,
            businessType: "Ecommerce"
          }
        }
    }

    console.log("Sign Up Body", body);


    const response = await PostSignUpData(body);
    if(response){
      alert("Account Created Successfully. Please Sign In");
      setIsCreateAccount(false);
    }

  }

  const HandleSignIn = async (e) => {
    e.preventDefault();

    let body = {
      request :{
          requestData :{
              mobileNo:SignUpDetails.loggedInNumber,
              pin:SignUpDetails.loggedInPassword
          }
      }
    }

  console.log("Sign in body",body);

  const response = await PostSignInData(body);

  if(response){
    navigate('/user/dashboard');
    login(response?.responseObject?.userDetails);
  }


  }



  return (
    <section className="loginBg ">
       <div className="w-full h-full flex  justify-center items-center backdrop-brightness-[0.35]">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

        <div className="w-full bg-white rounded-2xl shadow  md:mt-0 sm:max-w-md xl:p-0 ">
        <div className="mt-4 flex justify-center">
          <img className="h-16" src="/images/SprintrLogo.png" alt="logo" />
        </div>
          {IsCreateAccount ? (
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-2xl ">
                Create Account
              </h1>
              <form className="space-y-4 " onSubmit={HandleSignUp}>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="space-y-2">
                    <label>Customer Name<span className="text-red-400" >*</span></label>
                    <Input
                      isRequired
                      value={SignUpDetails.customerName || ''}
                      onChange={({ target }) => {
                        setSignUpDetails((data) => ({
                            ...data,
                            customerName: target.value,
                          }));
                      }}
                      borderColor={"gray.400"}
                      placeholder={"Customer Name"}
                    />
                  </div>
                <div className="space-y-2">
                  <label>Mobile No.<span className="text-red-400" >*</span></label>
                  <InputGroup size='md'>
                    <Input
                      isRequired
                      value={SignUpDetails.mobileNo || ''}
                      onChange={({target})=>{
                          setSignUpDetails((data)=>({
                              ...data,
                              mobileNo: target.value
                            }))
                       }} 
                      type="number"
                      borderColor={"gray.400"}
                      placeholder={"Mobile No."}
                    />
                    {/* <InputRightElement width='4.7rem' mr={1}>
                      {!VerificationStatus?.isOtpRequest &&<Button onClick={()=>{
                        setVerificationStatus({...VerificationStatus,isOtpRequest:true})
                      }} h='1.75rem' colorScheme="purple" size='sm'>
                        Get OTP
                      </Button>}
                    </InputRightElement> */}
                  </InputGroup>
                </div>

                </div>
                {/* {VerificationStatus?.isOtpRequest && <> <div className="space-y-2">
                  <label>Enter OTP<span className="text-red-400" >*</span></label>
                      <HStack spacing="3">
                       <PinInput value={SignUpDetails.pin || ''}
                    onChange={(pinValue)=>{
                        setSignUpDetails((data)=>({
                            ...data,
                            pin: pinValue
                          }))
                     }}  type='number' mask >
                         <PinInputField borderColor={'gray.400'} />
                         <PinInputField borderColor={'gray.400'} />
                         <PinInputField borderColor={'gray.400'} />
                         <PinInputField borderColor={'gray.400'} />
                       </PinInput>
                      </HStack>
                </div>

                <Button onClick={()=>{
                  setVerificationStatus({isOtpRequest:false,isOtpVerified:true})
                }} w={"full"} colorScheme="purple">
                  Verify
                </Button> 
                </>} */}

                {VerificationStatus?.isOtpVerified && <>
                  <div className="space-y-2">
                  <label>E-mail</label>
                  <Input
                    value={SignUpDetails.email || ''}
                    onChange={({target})=>{
                        setSignUpDetails((data)=>({
                            ...data,
                            email: target.value
                          }))
                     }} 
                    type="email"
                    borderColor={"gray.400"}
                    placeholder={"E-mail"}
                  />
                </div>
                  <div className="space-y-2">
                  <label>Password</label>
                  <Input
                    value={SignUpDetails.password || ''}
                    onChange={({target})=>{
                        setSignUpDetails((data)=>({
                            ...data,
                            password: target.value
                          }))
                     }} 
                    type="password"
                    borderColor={"gray.400"}
                    placeholder={"Password"}
                  />
                </div>
                <div className="space-y-2">
                  <label>Pin<span className="text-red-400" >*</span></label>
                      <HStack spacing="3">
                       <PinInput value={SignUpDetails.pin || ''}
                    onChange={(pinValue)=>{
                        setSignUpDetails((data)=>({
                            ...data,
                            pin: pinValue
                          }))
                     }}  type='number' mask >
                         <PinInputField borderColor={'gray.400'} />
                         <PinInputField borderColor={'gray.400'} />
                         <PinInputField borderColor={'gray.400'} />
                         <PinInputField borderColor={'gray.400'} />
                       </PinInput>
                      </HStack>
                </div>
                <div className="flex items-center justify-between">
                  <Checkbox 
                   isRequired
                   isChecked={SignUpDetails.termsCondition} 
                   onChange={({target})=>{setSignUpDetails((prevData)=>{
                    return {...prevData,
                              termsCondition: target.checked
                            }
                   })}} size={"md"}>
                    <span className="text-sm">Agree to Terms & Conditions</span>
                  </Checkbox>
                </div>
                <Button type="submit" w={"full"} colorScheme="purple">
                  Sign Up
                </Button>
                </>}
                <div className="text-center">
                  <Button
                    leftIcon={<FiArrowLeft/>}
                    onClick={() => {
                      setIsCreateAccount(false);
                    }}
                    size={"sm"}
                    colorScheme={"messenger"}
                    variant={"link"}
                  >
                    Back to Sign In
                  </Button>
                </div>
              </form>
            </div>
          ) : (
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-700 md:text-2xl ">
                Sign in to your account
              </h1>
              <form className="space-y-4 " onSubmit={HandleSignIn}>
                <div className="space-y-2">
                  <label>Mobile No.</label>
                  <Input 
                    isRequired
                    value={SignUpDetails.loggedInNumber || ''}
                    onChange={({target})=>{
                      setSignUpDetails((data)=>{
                        return {
                          ...data,
                          loggedInNumber:target.value
                        }
                      })
                    }}
                    type="number"
                    borderColor={"gray.400"}
                    placeholder={"Mobile No."}
                  />
                </div>
                <div className="space-y-2">
                  <label>Pin Password<span className="text-red-400" >*</span></label>
                      <HStack spacing="3">
                       <PinInput value={SignUpDetails.loggedInPassword || ''}
                    onChange={(pinValue)=>{
                        setSignUpDetails((data)=>({
                            ...data,
                            loggedInPassword: pinValue
                          }))
                     }}  type='number' mask >
                         <PinInputField borderColor={'gray.400'} />
                         <PinInputField borderColor={'gray.400'} />
                         <PinInputField borderColor={'gray.400'} />
                         <PinInputField borderColor={'gray.400'} />
                       </PinInput>
                      </HStack>
                </div>
                <div className="flex items-center justify-end">
                  <Button
                    size={"sm"}
                    variant={"link"}
                    colorScheme={"messenger"}
                  >
                    Forgot Pin?
                  </Button>
                </div>
                <Button isLoading={IsSignInLoading} loadingText="Signing In..." type="submit" w={"full"} colorScheme="purple">
                  Sign In
                </Button>
                <div className="text-center">
                 <p className="text-sm text-gray-500 ">
                   Don’t have an account yet?{" "}
                   <Button
                     onClick={() => {
                       setIsCreateAccount(true);
                     }}
                     size={"sm"}
                     colorScheme={"messenger"}
                     variant={"link"}
                   >
                     Sign Up
                   </Button>
                 </p>
                </div>
              </form>
            </div>
          )}

        </div>
      </div>
      </div>
    </section>
  );
}

export default Login;
