import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import UserSidebar from "./UserSidebar";
import UserHeader from "./UserHeader";

const UserOutlet = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="flex h-screen bg-slate-300 overflow-hidden">
    <UserSidebar  sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
      <UserHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
        <main className="no-scrollbar overflow-auto pb-20">
          <div className="px-3 pt-6 pb-20 w-full max-w-10xl mx-auto">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default UserOutlet;
