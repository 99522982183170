import { Button } from "@chakra-ui/react";
import React from "react";

function BecomeACourier() {
  return (
    <div>
      <section className="text-gray-600">
        <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className=" sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Join Logistrix as a
              <br className="hidden lg:inline-block" />
              Delivery Partner
            </h1>
            <p className="mb-8 leading-relaxed">
            We, at logistriX are constantly looking to expand our network with the help of our delivery partners. Be a part of the growing community of Delivery partners in Delhi NCR.
            </p>
            <div className="flex justify-center">
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSeZ9wmlY4XA6xV-mk1sRU3w4Td4mbDh5ljW5F0eke-B8v0Eqw/viewform" target="_blank" rel="noreferrer">
               <Button colorScheme="purple">Register Now</Button>

              </a>
            </div>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src="https://freschopsbar.com/assets/images/delivery.jpg"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default BecomeACourier;
