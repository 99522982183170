import { Button, Input, InputGroup, InputRightAddon, Select, Switch, Textarea, Tooltip, } from "@chakra-ui/react";
import React, { useState } from "react";
import { Info, MapPin, Minus, Plus } from "react-feather";
import DatePicker from "react-datepicker";
import { usePlacesWidget } from "react-google-autocomplete";
import { useEffect } from "react";
import {useAuth} from '../Utils/AuthContext';
import usePost from '../Utils/usePost';
import { BsBoxSeam } from "react-icons/bs";
const AddOrder = () => {

  const { user } = useAuth();
  const { IsPostLoading: IsPlaceOrderLoading, PostData: PostPlaceOrderData } = usePost('/sprint/order/place/order');
  console.log("User Data",user);
  const [VehicleType, setVehicleType] = useState('');
  const [OrderDetails, setOrderDetails] = useState({
    isScheduleDelivery: false
  })
  const [DropLocationList, setDropLocationList] = useState([
    {
      dropId: 1,
      dropLocation: "",
    },
  ]);

  const handleAddDropLocation = () => {
    setDropLocationList([
      ...DropLocationList,
      { dropId: DropLocationList.length + 1, dropLocation: "" },
    ]);
  };

  const handleRemoveDropLocation = (dropId) => {
    setDropLocationList(DropLocationList.filter(location => location.dropId !== dropId));
  };
  
  const handleInputChange = (dropId, value) => {
    setDropLocationList(DropLocationList.map(location =>
      location.dropId === dropId
        ? { ...location, dropLocation: value }
        : location
    ));
  };

  const [DropOffLocation, setDropOffLocation] = useState();
  const [PickUpLocation, setPickUpLocation] = useState();

  const { ref: PickRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_PLACES_API,
    onPlaceSelected: (place) => {
      console.log("Selected Pick Up",place);

      const pickupLatitude = place?.geometry?.location?.lat();
      const pickupLongitude = place?.geometry?.location?.lat(); 
      let pickupAddress = place?.formatted_address;
      let pickupCity = "";
      let pickupState = "";
      let pickupZip = "";

    place.address_components.forEach(component => {
      const types = component.types;

      if (types.includes("locality")) {
        pickupCity = component.long_name;
      }

      if (types.includes("administrative_area_level_1")) {
        pickupState = component.long_name;
      }
      if (types.includes("postal_code")) {
        pickupZip = component.long_name;
      }
    });

      setPickUpLocation({
        pickupLatitude,
        pickupLongitude,
        pickupAddress,
        pickupCity,
        pickupState,
        pickupZip,
      });
    },
    options: {
      fields: ["address_components","formatted_address", "geometry", "icon", "name"],
      types: ["establishment"],
      componentRestrictions: { country: "in" },
    },
  });

  const { ref: DropRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_PLACES_API,
    onPlaceSelected: (place) => {
      console.log("Selected Drop Off",place);

      const latitude = place?.geometry?.location?.lat();
      const longitude = place?.geometry?.location?.lat();
      let address = place?.formatted_address;
      let state = '';
      let city = '';
      let zip = '';


      place.address_components.forEach(component => {
        const types = component.types;
  
        if (types.includes("locality")) {
          state = component.long_name;
        }
  
        if (types.includes("administrative_area_level_1")) {
          city = component.long_name;
        }
        if (types.includes("postal_code")) {
          zip = component.long_name;
        }
      });

      setDropOffLocation({
        latitude,
        longitude,
        address,
        state,
        city,
        zip
      })
    },
    options: {
      fields: ["address_components","formatted_address", "geometry", "icon", "name"],
      types: ["establishment"],
      componentRestrictions: { country: "in" },
    },
  });


  console.log("Pick Up Location", PickUpLocation);
  console.log("Drop Off Location", DropOffLocation);


  const HandlePlaceOrder = async () => {
    let body = {
      request: {
          requestData: {
              scheduled: false,
              pickupDateTime: new Date(),
              pickupLatitude: PickUpLocation?.pickupLatitude, 
              pickupLongitude: PickUpLocation?.pickupLongitude,  
              pickupAddress: PickUpLocation?.pickupAddress,  
              pickupCity: PickUpLocation?.pickupCity,  
              pickupState: PickUpLocation?.pickupState,  
              pickupZip: PickUpLocation?.pickupZip,  
              landmark: PickUpLocation?.landmark,
              contact: user?.mobileNo,
              additionalInstructions: PickUpLocation?.additionalInstructions,
              vehicleType: "2 WHEELEAR",
              dropDetailsList: [
                  {
                      packetNo: "12345",
                      latitude: DropOffLocation?.latitude ,
                      longitude: DropOffLocation?.longitude ,
                      address: DropOffLocation?.address ,
                      state: DropOffLocation?.state ,
                      city: DropOffLocation?.city ,
                      zip: DropOffLocation?.zip ,
                      landmark: DropOffLocation?.landmark,
                      paymentStatus: "PAID",
                      paymentAmount: "100",
                      contact: DropOffLocation?.contact
                  }
              ]
          }
      }
    }
 
    console.log("Place Order Body",body);

    const response = await PostPlaceOrderData(body);
    
  }

  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Add Order</h1>
      </div>

      <div className="flex gap-6">
        {/* <div className="w-3/5 bg-white p-4 rounded-2xl drop-shadow-md">
          <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
            <h1 className="text-lg font-bold text-gray-800">
              Delivery Details
            </h1>
          </div>

          <div>
            <div className="flex relative pb-8">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-purple-500 inline-flex items-center justify-center text-white relative z-10">
                <MapPin />
              </div>
              <div className="flex-grow pl-4">
                <div className="space-y-2">
                  <label>Add Pickup Location</label>
                  <InputGroup>
                    <Select
                      borderColor={"gray.400"}
                      placeholder="Select Address"
                    >
                      <option value="option1">Option 1</option>
                      <option value="option2">Option 2</option>
                      <option value="option3">Option 3</option>
                    </Select>
                    <Tooltip
                      placement="top"
                      rounded="5px"
                      hasArrow
                      label="Add Pickup Location"
                      bg="gray.700"
                      color="white"
                    >
                      <InputRightAddon
                        p={0}
                        bgcolor="purple"
                        children={
                          <Button
                            colorScheme="purple"
                            children={<Plus size="16px" />}
                          />
                        }
                      />
                    </Tooltip>
                  </InputGroup>
                </div>
              </div>
            </div>

            {DropLocationList?.map((data,i) => {
              return (
                <div key={data?.dropId} className="flex relative pb-8">
                  <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                    <div className="h-full border-2 border-dashed border-gray-200 pointer-events-none"></div>
                  </div>
                  <div className="flex-shrink-0 w-10 h-10 rounded-full bg-purple-500 inline-flex items-center justify-center text-white relative z-10">
                    <MapPin />
                  </div>
                  <div className="flex-grow pl-4">
                    <div className="space-y-2">
                      <label>{i === 0 ? "Add Drop Location" : `Drop Location ${i+1}`}</label>
                      <InputGroup>
                        <Input
                          ref={ref}
                          value={data?.dropLocation}
                          onChange={({target})=>{
                            handleInputChange(data?.dropId,target.value)
                          }}
                          borderColor={"gray.400"}
                          placeholder="Enter Drop Location"
                        />
                        <Tooltip
                          placement="top"
                          rounded="5px"
                          hasArrow
                          label={data.dropId === 1 ? "Add Location" : "Remove Location"}
                          bg="gray.700"
                          color="white"
                        >
                          <InputRightAddon
                            p={0}
                            bgcolor="purple"
                            children={
                              <Button
                                onClick={()=>{
                                  if(data.dropId === 1){
                                    handleAddDropLocation();
                                  }else{
                                    handleRemoveDropLocation(data.dropId)
                                  }
                                }}
                                colorScheme="purple"
                                children={data.dropId === 1 ?  <Plus size="16px" /> : <Minus size={'16px'} />}
                              />
                            }
                          />
                        </Tooltip>
                      </InputGroup>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="flex relative pb-8">
              <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div className=" w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div className="flex-shrink-0 w-10 h-10 rounded-full bg-purple-500 inline-flex items-center justify-center text-white relative z-10">
                <Info />
              </div>
              <div className="flex-grow pl-4">
                <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label>Additional Info</label>
                  <Textarea
                    borderColor={"gray.400"}
                    placeholder="Additional Info"
                  />
                </div>
                  <div>
                  <div className="items-center space-x-8 mb-6">
                   <label>Schedule Delivery</label>
                   <Switch colorScheme="green" isChecked={OrderDetails?.isScheduleDelivery} onChange={({target})=>{
                    setOrderDetails({
                      ...OrderDetails,
                      isScheduleDelivery: target.checked
                    })
                   }} />
                 </div>
                 {OrderDetails?.isScheduleDelivery && <div className="overflow-visible" >
                   <DatePicker 
                     portalId="root-portal"
                     className={`border border-gray-400 bg-white   text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none `}
                     // selected={}
                     // onChange={(date) =>{
                     // }}
                     placeholderText="Select Time"
                     showTimeSelect
                     showTimeSelectOnly
                     timeIntervals={15}
                     timeCaption="Time"
                     dateFormat="h:mm aa"
                   />
                 </div>}
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div> */}

        <div className="w-3/5 bg-white p-4 rounded-2xl drop-shadow-md space-y-4">
          <div className="border-b flex items-center justify-between border-slate-400 pb-2">
            <h1 className="text-lg font-bold text-gray-800">
              Delivery Details
            </h1>
          </div>

          <div className="space-y-2">
            <label>Pick-up Location</label>
            <Input ref={PickRef} borderColor={'gray.400'} placeholder="Enter Pickup Location" />
          </div>

          <div className="space-y-2">
            <label>Pick-up Landmark</label>
            <Input 
              value={PickUpLocation?.landmark} 
              onChange={({target})=>{
                setPickUpLocation({
                  ...PickUpLocation,
                  landmark: target.value
                });
              }} 
              borderColor={'gray.400'} 
              placeholder="Enter Pickup Landmark" />
          </div>

          <div className="space-y-2">
            <label>Drop Location</label>
            <Input ref={DropRef} borderColor={'gray.400'} placeholder="Enter Drop-off Location" />
          </div>

          <div className="space-y-2">
            <label>Drop-off Landmark</label>
            <Input 
              value={DropOffLocation?.landmark} 
              onChange={({target})=>{
                setDropOffLocation({
                  ...DropOffLocation,
                  landmark: target.value
                });
              }} 
              borderColor={'gray.400'} 
              placeholder="Enter Drop-off Landmark" />
          </div>

          <div className="space-y-2">
            <label>Contact No.</label>
            <Input 
              value={DropOffLocation?.contact} 
              onChange={({target})=>{
                setDropOffLocation({
                  ...DropOffLocation,
                  contact: target.value
                });
              }} 
              borderColor={'gray.400'} 
              placeholder="Enter Drop-off Contact No." />
          </div>

          <div className="space-y-2">
            <label>Additional Instructions</label>
            <Textarea 
              value={PickUpLocation?.additionalInstructions} 
              onChange={({target})=>{
                setPickUpLocation({
                  ...PickUpLocation,
                  additionalInstructions: target.value
                });
              }} 
              borderColor={'gray.400'} 
              placeholder="Enter Additional Instructions" />
          </div>

        </div>
        <div className="w-2/5 bg-white p-4 rounded-2xl h-fit drop-shadow-md">
        <div className="space-y-4 mb-4">
          <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
            <h1 className="text-lg font-bold text-gray-800">
              Available Vehicles
            </h1>
          </div>              <div onClick={()=>{setVehicleType("Motorcycle")}} className={`flex justify-between items-center ${VehicleType === "Motorcycle" && "bg-blue-100 border-blue-400" } ${VehicleType !== "Motorcycle" && "hover:bg-blue-50 hover:border-blue-100" } cursor-pointer p-2 border-2 rounded-lg`}>
                <div className="flex items-center gap-4">
                 <img src="/images/BikeDelivery.png" className="h-12" alt="Bike Delivery" />
                 <div>
                  <h6 className="text-lg font-bold text-gray-800">Motorcycle</h6>
                  <p className="text-xs text-gray-500">Best for delivering documents & daily essentials</p>
                  <p className="text-xs text-gray-500"><BsBoxSeam className="inline-block mb-0.5"/> 0.4 x 0.4 x 0.4 Meter | Upto 20Kg</p>
                 </div>
                </div>
              </div>

              <div onClick={()=>{setVehicleType("3-Wheeler")}} className={`flex justify-between items-center ${VehicleType === "3-Wheeler" && "bg-blue-100 border-blue-400" } ${VehicleType !== "3-Wheeler" && "hover:bg-blue-50 hover:border-blue-100" } cursor-pointer p-2 border-2 rounded-lg`}>
                <div className="flex items-center gap-4">
                 <img src="/images/AutoDelivery.png" className="h-12" alt="Bike Delivery" />
                 <div>
                  <h6 className="text-lg font-bold text-gray-800">3-Wheeler</h6>
                  <p className="text-xs text-gray-500">Best for delivering garments & household equipments</p>
                  <p className="text-xs text-gray-500"><BsBoxSeam className="inline-block mb-0.5"/> 1.5 x 1.3 x 1.3 Meter | Upto 500</p>
                 </div>
                </div>
              </div>

              <div onClick={()=>{setVehicleType("Tata Ace")}} className={`flex justify-between items-center ${VehicleType === "Tata Ace" && "bg-blue-100 border-blue-400" } ${VehicleType !== "Tata Ace" && "hover:bg-blue-50 hover:border-blue-100" } cursor-pointer p-2 border-2 rounded-lg`}>
                <div className="flex items-center gap-4">
                 <img src="/images/TruckDelivery.png" className="h-12" alt="Bike Delivery" />
                 <div>
                  <h6 className="text-lg font-bold text-gray-800">Tata Ace</h6>
                  <p className="text-xs text-gray-500">Best for delivering furniture & commercial goods</p>
                  <p className="text-xs text-gray-500"><BsBoxSeam className="inline-block mb-0.5"/> 2.2 x 1.4 x 1.8 Meter | Upto 750</p>
                 </div>
                </div>
              </div>

              <div onClick={()=>{setVehicleType("8ft")}} className={`flex justify-between items-center ${VehicleType === "8ft" && "bg-blue-100 border-blue-400" } ${VehicleType !== "8ft" && "hover:bg-blue-50 hover:border-blue-100" } cursor-pointer p-2 border-2 rounded-lg`}>
                <div className="flex items-center gap-4">
                 <img src="/images/8ftDelivery.png" className="h-12" alt="Bike Delivery" />
                 <div>
                  <h6 className="text-lg font-bold text-gray-800">8ft</h6>
                  <p className="text-xs text-gray-500">Best for delivering office furniture & heavy machinery</p>
                  <p className="text-xs text-gray-500"><BsBoxSeam className="inline-block mb-0.5"/> 2.5 x 1.4 x 1.8 Meter | Upto 1000</p>
                 </div>
                </div>
              </div>
            </div>

          <div className="space-y-4">
          <div className="border-b flex items-center justify-between border-slate-200 pb-2 mb-4">
            <h1 className="text-lg font-bold text-gray-800">
              Order Details
            </h1>
          </div>  

            <div className="flex justify-between">
              <h6 className="font-medium">Delivery Charge:</h6>
              <p className="font-medium">0</p>
            </div>
            <div className="flex justify-between">
              <h6 className="font-medium">Handling Charge:</h6>
              <p className="font-medium">0</p>
            </div>
            <div className="flex justify-between">
              <h6 className="font-medium">Extra Charge:</h6>
              <p className="font-medium">0</p>
            </div>


            <Button isLoading={IsPlaceOrderLoading} loadingText="Placing Order..." onClick={HandlePlaceOrder} className="w-full" colorScheme="purple" >Place Order</Button>

          </div>
      </div>
    </div>
    </div>
  );
};


export default AddOrder;
