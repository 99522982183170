import axios from 'axios';
import React, { createContext, useContext, useState, useEffect } from 'react';
// const AuthContext = createContext();

const AuthContext = createContext({
  isLoggedIn: false,
  userData: null,
  authToken: null,
  signIn: () => {},
  signOut: () => {},
  isAuthenticated: () => {}
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setisLoading] = useState(true);

  const [state, setState] = useState({
    isLoggedIn: false,
    userData: null,
    authToken: null
  });

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setisLoading(false);
  }, []);

  const signIn = async (loginData) => {
    console.log("Login Data",loginData);
    try {
      const response = await axios.post(`${process.env.REACT_APP_LOGI_URL}/sprint/user/autenticateby/pin`,loginData);
      console.log("Login Response",response);
      
    } catch (err) {
      console.error(err);
    }


  }
  
  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user'); 
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
