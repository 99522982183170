import React, { useCallback, useMemo, useState } from "react";
import { AgGridReact } from 'ag-grid-react';
import { Button } from "@chakra-ui/react";
import {LuCheckCircle} from 'react-icons/lu';

const MyShipments = () => {


  const [OrderStatus, setOrderStatus] = useState('Ongoing');
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [rowData, setRowData] = useState([
    { make: "Tesla", model: "Model Y", price: 64950, electric: true },
    { make: "Ford", model: "F-Series", price: 33850, electric: false },
    { make: "Toyota", model: "Corolla", price: 29600, electric: false },
  ]);
  
  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    { field: "make" },
    { field: "model" },
    { field: "price" },
    { field: "electric" }
  ]);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    resizable: true,
  }));

  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">My Shipments</h1>
        <div className="space-x-2">
          <Button size={'sm'} rounded={'full'} onClick={()=>{setOrderStatus('Ongoing')}} variant={OrderStatus === "Ongoing" ? 'solid' : 'outline'} leftIcon={OrderStatus === "Ongoing" ? <LuCheckCircle/> : ''} colorScheme="blue">Ongoing</Button>
          <Button size={'sm'} rounded={'full'} onClick={()=>{setOrderStatus('Completed')}} variant={OrderStatus === "Completed" ? 'solid' : 'outline'} leftIcon={OrderStatus === "Completed" ? <LuCheckCircle/> : ''} colorScheme="green">Completed</Button>
          <Button size={'sm'} rounded={'full'} onClick={()=>{setOrderStatus('Cancelled')}} variant={OrderStatus === "Cancelled" ? 'solid' : 'outline'} leftIcon={OrderStatus === "Cancelled" ? <LuCheckCircle/> : ''} colorScheme="red">Cancelled</Button>
        </div>
      </div>

      <div className="ag-theme-alpine mb-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={rowData} // Row Data for Rows
          columnDefs={colDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
};

export default MyShipments;
