import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react'
import { useAuth } from '../Utils/AuthContext';
import UserMenu from '../Utils/UserMenu';

function Navbar() {
  const { user } = useAuth();
  return (
    <nav className='bg-gray-100 py-2'>
          <div className=' h-14  max-w-screen-xl mx-auto drop-shadow-md'>
        <div className='px-3 md:px-8  flex justify-between items-center w-full h-full'>
            <div className='flex items-center'>
              <NavLink to="/">
               <img className='h-9 md:h-12' alt='logistrix' src='/images/SprintrLogo.png'/>
              </NavLink>
            </div>
            <div >
                <ul className='hidden md:flex space-x-4'>
                  {/* <NavLink to="/deliver">
                    <li  className={`px-3 py-2 font-medium rounded-lg text-slate-700 hover:bg-slate-300 hover:text-slate-700 focus:rounded-t-lg`}>
                     Deliver Order
                   </li>
                  </NavLink> */}

                  {!user ?                    
                     <NavLink to="/login">
                       <li  className={`px-3 py-2 font-medium rounded-lg text-slate-700 hover:bg-slate-300 hover:text-slate-700 focus:rounded-t-lg`}>
                         <i className="fa-regular fa-user mr-2"></i>Login
                       </li>
                     </NavLink> : ''
                      }
                  {/* <NavLink to="/courier"> */}
                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSeZ9wmlY4XA6xV-mk1sRU3w4Td4mbDh5ljW5F0eke-B8v0Eqw/viewform" target="_blank" rel="noreferrer">
                   <button className=" rounded-lg px-3 py-2 bg-purple-600  hover:bg-purple-600 hover:drop-shadow-lg text-white font-semibold" >Become a Delivery Partner</button>
                  </a>
                  {/* </NavLink> */}

               {user &&  <UserMenu/>}

                </ul>

                <div className='flex gap-2 md:hidden'>
                  <ul>
                  <NavLink to="/deliver">
                  <button className="text-sm rounded-lg px-2 py-1.5 bg-purple-600  hover:bg-purple-600 hover:drop-shadow-lg text-white font-semibold" >Deliver Order</button>
                  </NavLink>
                  </ul>

                  
                <div className='my-auto '>
                <Menu>
                  
                  <MenuButton>
                  <i className="fa-solid fa-bars p-2"></i>
                  </MenuButton>
                  <MenuList>
                  <NavLink to="/login">
                    <MenuItem>
                    <i className="fa-regular fa-user mr-2"></i>Login
                    </MenuItem>
                </NavLink>
                    <NavLink to="/courier">
                    <MenuItem>
                    Become a Delivery Partner
                    </MenuItem>
                    </NavLink>

                  </MenuList>
                </Menu>
                </div>
                </div>



            </div>


        </div>



    </div>
    </nav>
  )
}

export default Navbar