import React, { useState } from "react";
import { Box, Input, Button, Select, Flex } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { CheckCircle} from "react-feather";
import { usePlacesWidget } from "react-google-autocomplete";
import { calculateDistance } from "../Utils/DistCalUtil";

const PlaceOrderSteps = [
    { label: "Step 1", description: "Pick-up Details" },
    { label: "Step 2", description: "Drop-off Details" },
    { label: "Step 3", description: "Payment" },
  ];

  
function DeliverOrder() {

  const point1 = {
    latitude: 28.7426862,   // Latitude of Point 1
    longitude: 77.19790449999999, // Longitude of Point 1
  };

  const point2 = {
    latitude: 28.7016158,   // Latitude of Point 2
    longitude: 77.1231167, // Longitude of Point 2
  };

  const distance = calculateDistance(
    point1.latitude,
    point1.longitude,
    point2.latitude,
    point2.longitude
  );

  console.log("Calculated Distance",distance);


  const [DeliverPeriod, setDeliverPeriod] = useState(1);

  const [OrderDetails, setOrderDetails] = useState({
    packageType: "",
    packageWeight: 0,
    isScheduled: false,
    payAt: "",
    pickupDate: ""
  })
  
  const [PickUpDetails, setPickUpDetails] = useState({
    name: "",
    mobileNo: "",
    address: "",
    pinCode: "",
    landmark: ""
  });
  
  const [DropOffDetails, setDropOffDetails] = useState({
    name: "",
    mobileNo: "",
    address: "",
    pinCode: "",
    landmark: ""
  });

  const {
    nextStep: PlaceOrderNext,
    prevStep: PlaceOrderPrev,
    reset: PlaceOrderReset,
    activeStep: PlaceOrderActiveStep,
  } = useSteps({ initialStep: 0 });

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_PLACES_API,
    onPlaceSelected: (place) => {
      console.log("Selected Place",place);
    },
    options: {
      // fields: ["address_components", "geometry", "icon", "name"],
      types: ["establishment"],
      componentRestrictions: { country: "in" },
    },
  });


  const SaveOrderDetails = async()=>{
    let body = {
      request: {
          requestData: {
              orderDetails: OrderDetails,
              pickupDetails: PickUpDetails,
              dropDetails: DropOffDetails
          }
      }
  }

  console.log("Order Body",body);
  }

  return (
    <div className="">
      {/* <div className=" max-w-3xl mx-auto space-y-6 py-10">

        <div className="flex justify-between items-center border-b border-gray-400  pb-2 text-left">
          <h1 className="text-gray-700 text-xl font-bold ">
            Place Order
          </h1>
        </div>

        <div className="flex justify-between">
        <div className="space-x-4">
          <Button onClick={()=>{
            setDeliverPeriod(1);
            setOrderDetails((prevData)=>{
              return {
                ...prevData,
                isScheduled: false
              }
            })
            }} variant={DeliverPeriod === 1? 'solid' : 'outline'} colorScheme='purple'><FontAwesomeIcon icon={faClock} className='mr-2' />Deliver Now</Button>
          <Button onClick={()=>{
            setDeliverPeriod(2);
            setOrderDetails((prevData)=>{
              return {
                ...prevData,
                isScheduled:true
              }
            })
            }} variant={DeliverPeriod === 2? 'solid' : 'outline'} colorScheme='purple'><FontAwesomeIcon icon={faCalendarDay} className='mr-2' />Schedule</Button>
        </div>

          <div>
            <Select 
             value={OrderDetails.packageWeight}
             onChange={({target})=>{
              setOrderDetails((prevData)=>{
                return {
                  ...prevData,
                  packageWeight : parseInt(target.value)
                }
              })
             }}
             borderColor={'gray.400'} bg placeholder='Select Weight'>
            <option value={'1'} >Upto 1 Kg</option>
            <option value={'5'} >Upto 5 Kg</option>
            <option value={'10'} >Upto 10 Kg</option>
            <option value={'15'} >Upto 15 Kg</option>
            <option value={'20'} >Upto 20 kg</option>
            </Select>
          </div>
        </div>



          <Steps
                checkIcon={CheckCircle}
                colorScheme="purple"
                size={'sm'}
                activeStep={PlaceOrderActiveStep}
              >
                <Step label={"Pick-up Details"}>
                <Box className='bg-white rounded-2xl p-4  items-end mb-4 space-y-2'>

      <div className='grid grid-cols-2 gap-4 mb-4'>

       <div className='space-y-2'>
         <label>Your Name</label>
         <Input 
          value={PickUpDetails.name || ''}  
          onChange={({target})=>{
            setPickUpDetails((prevData)=>{
              return {
                ...prevData,
                name: target.value
              }
            })
          }}
          borderColor={'gray.400'} placeholder='Your Name' />
       </div>

       <div className='space-y-2'>
         <label>Mobile No.</label>
         <Input           
          value={PickUpDetails.mobileNo || ''}  
          onChange={({target})=>{
            setPickUpDetails((prevData)=>{
              return {
                ...prevData,
                mobileNo: String(target.value)
              }
            })
          }} type={'number'} borderColor={'gray.400'} placeholder='Mobile No.' />
       </div>

       <div className='space-y-2'>
        <label>Pick-up Address</label>
        <Input ref={ref} borderColor={'gray.400'} placeholder='Pick-up Address'   />
      </div>

      <div className='space-y-2'>
        <label>Landmark</label>
        <Input 
          value={PickUpDetails.landmark || ''}  
          onChange={({target})=>{
            setPickUpDetails((prevData)=>{
              return {
                ...prevData,
                landmark: String(target.value)
              }
            })
          }}
          borderColor={'gray.400'} placeholder='Landmark' />
      </div>
        
      </div>


      {DeliverPeriod === 2 ?
      
      <div className='space-y-2'>
        <label>Pick-up Date</label>
        <Input 
          value={OrderDetails.pickupDate || ''}  
          onChange={({target})=>{
            setOrderDetails((prevData)=>{
              return {
                ...prevData,
                pickupDate: target.value
              }
            })
          }} type={'date'} borderColor={'gray.400'}  />
      </div>
     : ''}


      </Box>
                </Step>

                <Step label={"Drop-off Details"}>
                <Box className='bg-white rounded-2xl p-4  items-end mb-4 space-y-2'>

      <div className='grid grid-cols-2 gap-6'>

       <div className='space-y-2'>
         <label>Receiver's Name</label>
         <Input           
         value={DropOffDetails.name || ''}  
          onChange={({target})=>{
            setDropOffDetails((prevData)=>{
              return {
                ...prevData,
                name: target.value
              }
            })
          }} borderColor={'gray.400'} placeholder="Receiver's Name" />
       </div>

       <div className='space-y-2'>
         <label>Receiver's Mobile No.</label>
         <Input          
          value={DropOffDetails.mobileNo || ''}  
          onChange={({target})=>{
            setDropOffDetails((prevData)=>{
              return {
                ...prevData,
                mobileNo: String(target.value)
              }
            })
          }} type={'number'} borderColor={'gray.400'} placeholder="Receiver's Mobile No." />
       </div>
        
      </div>

      <div className="grid grid-cols-2 gap-6">
        
      <div className='space-y-2'>
        <label>Delivery Address</label>
        <Input borderColor={'gray.400'} placeholder='Delivery Address' />
      </div>

      <div className='space-y-2'>
        <label>Landmark</label>
        <Input          
          value={DropOffDetails.landmark || ''}  
          onChange={({target})=>{
            setDropOffDetails((prevData)=>{
              return {
                ...prevData,
                landmark: target.value
              }
            })
          }} borderColor={'gray.400'} placeholder='Landmark' />
      </div>

      </div>



      </Box>
                </Step>

                <Step label={"Payment"}>
                    <div>
                    <Box className='bg-white rounded-2xl p-4  items-end mb-4 space-y-2'>
        <label>Item Details</label>
        <Input 
         value={OrderDetails.packageType || ''} 
         onChange={({target})=>{setOrderDetails((prevData)=>{
          return {
            ...prevData,
            packageType: target.value
          }
         })}} 
         borderColor={'gray.400'} 
         placeholder='What are you sending?' />
      </Box>

      <Box className='bg-white rounded-2xl p-4  items-end mb-4 space-y-2'>
        <div className="flex justify-between items-center border-b border-gray-400  pb-2 text-left">
        <h1 className="text-gray-700 text-lg font-medium ">Secure your parcel</h1>
      </div>

      <div className='grid grid-cols-2 gap-6'>

       <div className='space-y-2'>
         <label>Parcel Value</label>
         <Input borderColor={'gray.400'} type='number' placeholder="Parcel Value" />
       </div>
        
      </div>
      </Box>
                    </div>
                </Step>

              </Steps>

              {PlaceOrderActiveStep === PlaceOrderSteps.length ? (
              <Flex px={4} width="100%" className="justify-end">
                <Button mr={4} onClick={PlaceOrderReset}>
                  Reset
                </Button>
              </Flex>
            ) : (
              <Flex width="100%" justify="flex-end">
                <Button
                  isDisabled={PlaceOrderActiveStep === 0}
                  mr={4}
                  onClick={PlaceOrderPrev}
                  colorScheme="purple"
                  variant="outline"
                >
                  Prev
                </Button>
                {PlaceOrderActiveStep === PlaceOrderSteps.length - 1 ? (
                  <Button onClick={()=>{
                    SaveOrderDetails();
                  }} colorScheme="purple">
                    Finish
                  </Button>
                ) : (
                  <Button colorScheme="purple" onClick={PlaceOrderNext}>
                    Continue
                  </Button>
                )}
              </Flex>
            )}

      </div> */}
      <section class="bg-white  h-screen flex items-center">
    <div class="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12 my-auto">
        <svg class="mx-auto mb-4 w-10 h-10 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M331.8 224.1c28.29 0 54.88 10.99 74.86 30.97l19.59 19.59c40.01-17.74 71.25-53.3 81.62-96.65c5.725-23.92 5.34-47.08 .2148-68.4c-2.613-10.88-16.43-14.51-24.34-6.604l-68.9 68.9h-75.6V97.2l68.9-68.9c7.912-7.912 4.275-21.73-6.604-24.34c-21.32-5.125-44.48-5.51-68.4 .2148c-55.3 13.23-98.39 60.22-107.2 116.4C224.5 128.9 224.2 137 224.3 145l82.78 82.86C315.2 225.1 323.5 224.1 331.8 224.1zM384 278.6c-23.16-23.16-57.57-27.57-85.39-13.9L191.1 158L191.1 95.99l-127.1-95.99L0 63.1l96 127.1l62.04 .0077l106.7 106.6c-13.67 27.82-9.251 62.23 13.91 85.39l117 117.1c14.62 14.5 38.21 14.5 52.71-.0016l52.75-52.75c14.5-14.5 14.5-38.08-.0016-52.71L384 278.6zM227.9 307L168.7 247.9l-148.9 148.9c-26.37 26.37-26.37 69.08 0 95.45C32.96 505.4 50.21 512 67.5 512s34.54-6.592 47.72-19.78l119.1-119.1C225.5 352.3 222.6 329.4 227.9 307zM64 472c-13.25 0-24-10.75-24-24c0-13.26 10.75-24 24-24S88 434.7 88 448C88 461.3 77.25 472 64 472z"/></svg>
        <h1 class="mb-4 text-4xl font-bold tracking-tight leading-none text-gray-900 lg:mb-6 md:text-5xl xl:text-6xl ">Coming Soon</h1>
        <p class="font-light text-gray-500 md:text-lg xl:text-xl ">We will start our operation soon.</p>
    </div>
  </section>
    </div>
  );
}

export default DeliverOrder;
