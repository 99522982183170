import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useAuth } from "./AuthContext";
import axios from "axios";

const usePost = (endpoint) => {

  const { userData, HandleSessionModal  } = useAuth();
  const toast = useToast();
  const id = "toast";
  const [IsPostLoading, setIsPostLoading] = useState(false);
  const [PostError, setPostError] = useState(null);

  const PostData = async (data) => {

    setIsPostLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LOGI_URL}${endpoint}`,
        data
      );
      console.log("Post Hook Response", response);

      toast({
        id,
        title: response?.data?.message,
        position: "top",
        status: "info",
        duration: 2000,
        isClosable: true
      });

      return response.data;
    } catch (err) {
      console.error("Post Hook PostError", err, err?.response?.data, err?.response?.statusText);
      setPostError(err?.message);

        toast({
          id,
          title: "ERROR",
          description: err?.response?.data?.message,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true
        })

      // if(err.response){
      //   if (typeof err?.response?.data !== "object") {
      //   toast({
      //     id,
      //     title: "ERROR",
      //     description: err?.response?.data?.message || err?.response?.statusText || "Something Went Wrong!!",
      //     position: "top",
      //     status: "error",
      //     duration: 2000,
      //     isClosable: true
      //   })
      // }
      // }else if (!toast.isActive(id)) {
      //   toast({
      //     id,
      //     title: "ERROR",
      //     description: err?.message,
      //     position: "top",
      //     status: "error",
      //     duration: 2000,
      //     isClosable: true
      //   })
      // }else{
      //   toast({
      //     id,
      //     title: "ERROR",
      //     description: "Something Went Wrong!!",
      //     position: "top",
      //     status: "error",
      //     duration: 2000,
      //     isClosable: true
      //   })
      // }

      // if(err.response){
      //   if(err.response.statusText === "Unauthorized" || err.response.status === 401){
      //       HandleSessionModal();
      //   }
      // }

    } finally {
      setIsPostLoading(false);
    }
  };

  return { IsPostLoading, PostError, PostData };
};

export default usePost;
